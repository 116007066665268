<template>
    <div class="contenedor-grafica-areas-accion row-center">


        <div class="filtro">
            <div class="inputs row-start">
                <div class="contenedor-input" v-if="dominios.ejes">
                    <multiselect v-model="filter.ejes" :options="dominios.ejes" placeholder="Eje estratégico" label="nombre"
                        :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false"
                        :showNoResults="false" track-by="nombre"></multiselect>
                </div>



                <div class="contenedor-input" v-if="dominios.areas_gobierno">
                    <multiselect v-model="filter.areas_gobierno" :options="dominios.areas_gobierno"
                        placeholder="Áreas de Gobierno, Distritos..." label="nombre" :multiple="true" :closeOnSelect="false"
                        :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                    </multiselect>
                </div>


                <div class="contenedor-input -m0" v-if="dominios.distritos">
                    <multiselect v-model="filter.distritos" :options="dominios.distritos" placeholder="Ámbito territorial"
                        label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false"
                        :showNoResults="false" track-by="nombre">
                    </multiselect>

                </div>

                <a @click="reset" class="erase">Borrar filtros</a>

            </div>
        </div>

        <!-- <grafica-barras v-if="actuaciones && dominios" :data="actuaciones" :filter="filter"></grafica-barras> -->


        <nav class="selector-areas-accion">
            <div class="container row-center">
                <a :active="selected == area.id" @click="setSelected(area.id)" class="area-accion" :key="key"
                    v-for="(area, key) in dominios.areas_accion" :title="area.nombre">{{ area.nombre }}</a>
            </div>
        </nav>

        <div class="contenedor-grafica contenedor-grafica-area" id="contenedor-grafica-accion">
            <div id="chartareascumplimientoaccion" :hidden="actuacionesfiltered.length == 0"></div>
            <article class="stat -small" id="article-actuacion">
                <strong>
                    <count-to :endVal="actuacionesfiltered.length" :separator="'.'"></count-to>
                </strong>
                <span>Actuaciones</span>
            </article>
        </div>




    </div>
</template>



<script>

import { mapGetters } from 'vuex';

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// import graficabarras from '@/components/parts/cumplimiento-barras-por-area'

export default {
    name: 'cumplimiento-por-areas',
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        if (this.actuaciones.length != 0) {
            this.setChart();
        }
    },
    data: () => ({
        selected: '',
        series: "",
        filter: {
            ejes: [],
            distritos: [],
            areas_gobierno: []
        },
    }),
    methods: {
        setChart() {

            if (this.dominios && this.dominios.areas_accion) {
                this.selected = this.dominios.areas_accion[0].id;
            }


            var root = am5.Root.new("chartareascumplimientoaccion");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            var chart = root.container.children.push(am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
                innerRadius: am5.percent(50),
                startAngle: 180,
                endAngle: 360,
                //radius: am5.percent(75), /** Se vuelve mas pequenio el grafico */
            }));

            this.series = chart.series.push(am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                alignLabels: false,
                startAngle: 180,
                endAngle: 360,
                //tooltipText: "{category}: ({porcentaje.formatNumber('#.')}%)",
            }));

            // Custosm colors
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/pie-series/
            this.series.get("colors").set("colors", [
                am5.color(0x6794dc),
                am5.color(0x6771dc),
                am5.color(0xff0000), // red for no-iniciadas
                am5.color(0x86a873),
                am5.color(0xbb9f06)
            ]);

            this.series.labels.template.setAll({
                //textType: "circular",
                centerX: am5.percent(50),
                centerY: 0,
                fontSize: 12,
                text: "{category} {porcentaje.formatNumber('#.')}%",

                maxWidth: 150,
                oversizedBehavior: "wrap",
                alignLabels: false,
                //inside: true,  /** el label queda adentro del grafico */
                // paddingTop: 50,
                // paddingLeft: 50,
                paddingTop: 20,
            });

            this.series.slices.template.set('tooltipText', '{category}: {porcentaje}%');
            this.series.labels.template.set('text', '{category}: {porcentaje}%');

            //this.series.data.setAll(this.setData());

            var legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 15,
                marginBottom: 15,
                fontSize: 12,
                clickTarget: "none"
            }));

            /** Leyenda bottom */
            legend.labels.template.setAll({
                fontSize: 14,
                fontWeight: "300"
            });

            legend.valueLabels.template.setAll({
                fontSize: 14,
                fontWeight: "400",
            });



            // legend.data.setAll(this.series.dataItems);


            this.series.appear(1000, 100);
        },
        setData() {

            let data;
            if (this.actuacionesfiltered) {
                //console.log("this.actuacionesfiltered", this.actuacionesfiltered);
                let terminadasvalue = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento == 1).length;
                let ejecucionvalue = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento == 2).length;
                let noiniciadavalue = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento >= 3).length;
                let totalgeneral = this.actuacionesfiltered.length;
                let terminadasporcentaje = Math.round((terminadasvalue / totalgeneral) * 100);
                let ejecucionporcentaje = Math.round((ejecucionvalue / totalgeneral) * 100);

                /** Controlo si terminadasporcentaje + ejecucionporcentaje > 100 (caso en que los 2 tienen decimales 0.5) entonces ejecucionporcentaje-1*/
                if (terminadasporcentaje + ejecucionporcentaje > 100) {
                    ejecucionporcentaje = ejecucionporcentaje - 1;
                }
                let noiniciadaporcentaje = 100 - (terminadasporcentaje + ejecucionporcentaje);

                /** Solo incluyo en data si el porcentaje != 0 */
                if (terminadasporcentaje != 0 && ejecucionporcentaje != 0 && noiniciadaporcentaje != 0) {
                    data = {
                        "terminadas": { value: terminadasvalue, category: "Terminadas", porcentaje: terminadasporcentaje, total: totalgeneral },
                        "ejecucion": { value: ejecucionvalue, category: "En ejecución", porcentaje: ejecucionporcentaje, total: totalgeneral },
                        "noiniciada": { value: noiniciadavalue, category: "No iniciada", porcentaje: noiniciadaporcentaje, total: totalgeneral },
                    };
                } else if (terminadasporcentaje != 0 && ejecucionporcentaje != 0) {
                    data = {
                        "terminadas": { value: terminadasvalue, category: "Terminadas", porcentaje: terminadasporcentaje, total: totalgeneral },
                        "ejecucion": { value: ejecucionvalue, category: "En ejecución", porcentaje: ejecucionporcentaje, total: totalgeneral },
                    };
                } else if (terminadasporcentaje != 0 && noiniciadaporcentaje != 0) {
                    data = {
                        "terminadas": { value: terminadasvalue, category: "Terminadas", porcentaje: terminadasporcentaje, total: totalgeneral },
                        "noiniciada": { value: noiniciadavalue, category: "No iniciada", porcentaje: noiniciadaporcentaje, total: totalgeneral },
                    };
                } else if (ejecucionporcentaje != 0 && noiniciadaporcentaje != 0) {
                    data = {
                        "ejecucion": { value: ejecucionvalue, category: "En ejecución", porcentaje: ejecucionporcentaje, total: totalgeneral },
                        "noiniciada": { value: noiniciadavalue, category: "No iniciada", porcentaje: noiniciadaporcentaje, total: totalgeneral },
                    };
                } else if (terminadasporcentaje != 0) {
                    data = {
                        "terminadas": { value: terminadasvalue, category: "Terminadas", porcentaje: terminadasporcentaje, total: totalgeneral },
                    };
                } else if (ejecucionporcentaje != 0) {
                    data = {
                        "ejecucion": { value: ejecucionvalue, category: "En ejecución", porcentaje: ejecucionporcentaje, total: totalgeneral },
                    };
                } else if (noiniciadaporcentaje != 0) {
                    data = {
                        "noiniciada": { value: noiniciadavalue, category: "No iniciada", porcentaje: noiniciadaporcentaje, total: totalgeneral },
                    };
                }

                //console.log("data", data);
            }


            // let data = {
            //     //"terminadayejecucion": { value: '', category: "Terminadas y en ejecución" },
            //     "terminadas": { value: '', category: "Terminadas" },
            //     "ejecucion": { value: '', category: "En ejecución" },
            //     //"noiniciada": { value: '', category: "No iniciada" },
            // };



            //console.log("this.actuacionesfiltered", this.actuacionesfiltered);

            if (this.actuacionesfiltered) {
                // data['terminadayejecucion'].value = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento == 1 || e.idactuacioncumplimiento == 2).length // Unifico terminadas y en ejecución
                // data['terminadas'].value = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento == 1).length;
                // data['ejecucion'].value = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento == 2).length;
                // data['noiniciada'].value = this.actuacionesfiltered.filter(e => e.idactuacioncumplimiento >= 3).length;
                // data['terminadas'].total = this.actuacionesfiltered.length;
                // data['ejecucion'].total = this.actuacionesfiltered.length;
                // data['noiniciada'].total = this.actuacionesfiltered.length;
                // data['terminadas'].porcentaje = Math.round((data['terminadas'].value / data['terminadas'].total) * 100);
                // data['ejecucion'].porcentaje = Math.round((data['ejecucion'].value / data['ejecucion'].total) * 100);
                // data['noiniciada'].porcentaje = 100 - data['terminadas'].porcentaje - data['ejecucion'].porcentaje;

                // // console.log("data", data);

                // /** Si porcentaje=0 entonces borro el item */
                // if (data['terminadas'].porcentaje == 0) {
                //     console.log("terminadas");
                // }
                // if (data['ejecucion'].porcentaje == 0) {
                //     console.log("ejecucion");
                // }
                // // if (data['noiniciada'].porcentaje == 0) {
                // //     console.log("noiniciada");
                // //     /** borrar noiniciada */
                // // }

                //console.log("data", data);
            }



            // console.log("data", Object.values(data));

            return Object.values(data);
        },
        setSelected(id) {
            this.selected = id;

            // let data = this.setData();
            // data.forEach((e, i) => {
            //     this.series.data.setIndex(i, e);
            // })
            this.series.data.setAll(this.setData());
        },
        reset() {
            this.filter = {
                ejes: [],
                distritos: [],
                areas_gobierno: [],
            };
        },

        updateChartDOM() {
            /** Refresh css of element chartareascumplimientoaccion change width 90%*/
            let chartdiv = document.getElementById('chartareascumplimientoaccion');
            chartdiv.style.width = '99.9%';

            setTimeout(() => {
                let chartdiv2 = document.getElementById('chartareascumplimientoaccion');
                chartdiv2.style.width = '100%';
            }, 100);
        },
        myEventHandler() {
            //console.log("event", e);
            this.updateChartDOM();
        }

    },
    computed: {
        ...mapGetters({
            actuaciones: 'getActuaciones',
            dominios: 'getDominios',
        }),
        actuacionesfiltered() {
            let filtered;

            //console.log("this.actuaciones", this.actuaciones);

            if (this.actuaciones && this.dominios) {

                filtered = JSON.parse(JSON.stringify(this.actuaciones));

                //console.log("filtered", filtered);

                //EJES
                if (this.filter.ejes.length != 0) {
                    let ejes = this.filter.ejes.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (ejes.some(eje => eje == actuacion.ideje));
                    })

                }

                //console.log("filtered ejes", filtered);

                //DISTRITOS
                if (this.filter.distritos.length != 0) {
                    let distritos = this.filter.distritos.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (distritos.some(distrito => distrito == actuacion.iddistrito));
                    })
                }

                //console.log("filtered distritos", filtered);


                //ÁREAS GOBIERNO
                if (this.filter.areas_gobierno.length != 0) {
                    let areas_gobierno = this.filter.areas_gobierno.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (areas_gobierno.some(area => area == actuacion.idareagobierno));
                    })
                }

                //console.log("filtered areas_gobierno", filtered);

                //console.log("this.selected", this.selected);

                //ÁREAS ACCIÓN
                //filtered = filtered.filter(e => this.selected == e.idareaaccion)
                if (this.selected) {
                    filtered = filtered.filter(actuaciones => {
                        let areas_accion = actuaciones.idareaaccion != null ? actuaciones.idareaaccion.split(',') : [];
                        return (areas_accion.some(area => area == this.selected));
                    })
                }

                //console.log("filtered accion", filtered);
            }

            //console.log("actuacionesfiltered", filtered)

            return filtered;
        }
    },
    watch: {
        'actuaciones'() {
            this.setChart();
        },
        'actuacionesfiltered': {
            deep: true,
            handler() {
                // let data = this.setData();
                // data.forEach((e, i) => {
                //     this.series.data.setIndex(i, e);
                // });

                this.series.data.setAll(this.setData());
            }
        }
    },
    components: {
        // 'grafica-barras' : graficabarras
    }
}
</script>

<style lang="scss" scoped>
#chartareascumplimientoaccion {
    /** Cumplimiento por Áreas de Acción */
    width: 100%;

    &[hidden=hidden] {
        opacity: 0;
        pointer-events: none;
        filter: grayscale(1)
    }
}

.contenedor-grafica {
    width: 50%;
    position: relative;

    .stat {
        position: absolute;
        left: 50%;
        bottom: 61px;
        transform: translateX(-50%);
        width: 160px;
        height: 80px;
        border-radius: 160px 160px 0 0;
    }
}

.contenedor-grafica-area {
    width: 60% !important;
}

.selector-areas-accion {
    width: 40%;

    .container {
        max-width: 350px;
        justify-content: flex-start;

        .area-accion {
            margin-bottom: 40px;
            opacity: .4;
            transition: 0s;

            &[active=true] {
                opacity: 1;
                transform: none;

            }
        }
    }
}

@media screen and (max-width:1100px) {
    .contenedor-grafica {
        width: 100%;
    }

    .contenedor-grafica-area {
        width: 100% !important;
    }


    .selector-areas-accion {
        width: 100%;
    }
}

@media screen and (max-width:540px) {
    .contenedor-grafica .stat {
        position: relative;
        left: auto;
        bottom: auto;
        transform: none;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        margin: 0 auto;
    }
}
</style>