<template>
    <div class="contenedor-grafica">
        <div id="chartdivbarras"></div>
    </div>
</template>

<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'grafica-barras',
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.setChart();
    },
    props: ['data'],
    data: () => ({
        root: '',
        series: [],
        owndata: []
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartdivbarras");


            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            root.numberFormatter.set("numberFormat", "####");

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                layout: root.verticalLayout
            }));


            let owndata = new Array();


            Object.entries(this.data).reverse().forEach((e) => {

                let el = {
                    "name": e[0],
                    "percent": e[1].percent,
                    terminadas: (!isNaN(e[1].terminadas * 100 / e[1].actuaciones)) ? Math.round(e[1].terminadas * 100 / e[1].actuaciones, 2) : 0,
                    ejecucion: (!isNaN(e[1].ejecucion * 100 / e[1].actuaciones)) ? Math.round(e[1].ejecucion * 100 / e[1].actuaciones, 2) : 0,
                    terminadasyejecucion: (!isNaN(e[1].terminadasyejecucion * 100 / e[1].actuaciones)) ? Math.round(e[1].terminadasyejecucion * 100 / e[1].actuaciones, 2) : 0,
                    //noiniciadas: (!isNaN(e[1].noiniciadas * 100 / e[1].actuaciones)) ? Math.round(e[1].noiniciadas * 100 / e[1].actuaciones, 2) : 0,
                    noiniciadas: 100 - Math.round(e[1].terminadas * 100 / e[1].actuaciones, 2) - Math.round(e[1].ejecucion * 100 / e[1].actuaciones, 2),
                };
                owndata.push(el);
            });


            //console.log(owndata);


            var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "name",
                zoomY: false,
                zoomX: false,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 5,
                }),
                tooltip: am5.Tooltip.new(root, {})
            }));

            yAxis.data.setAll(owndata);

            var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                min: 10,
                max: 100,
                renderer: am5xy.AxisRendererX.new(root, {}),
                numberFormat: "#'%'",
            }));


            // Add legend
            // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
            var legend = chart.children.push(am5.Legend.new(root, {
                centerX: 200,
                x: am5.p50,
                /** add padding-top */
                paddingTop: 20
            }));


            var that = this;

            function makeSeries(name, fieldName, color) {
                that.series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    etiqueta: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    baseAxis: yAxis,
                    valueXField: fieldName,
                    categoryYField: "name",
                    fill: color,
                }));

                that.series.columns.template.setAll({
                    tooltipText: "{etiqueta} {valueX}%",
                    tooltipY: am5.percent(80)
                });
                that.series.data.setAll(owndata);

                // Add custom colors 
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Manual_colors\]

                //console.log(chart.get("colors"));

                chart.get("colors").set("colors", [
                    am5.color(0x67b7dc),
                    am5.color(0x6794dc),
                    am5.color(0xff0000), // red for no-iniciadas
                    am5.color(0x86a873),
                    am5.color(0xbb9f06)
                ]);

                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                that.series.appear();

                that.series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        sprite: am5.Label.new(root, {
                            //text: "{valueX}%",
                            fill: root.interfaceColors.get("alternativeText"),
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                            fontSize: 11
                        })
                    });
                });

                legend.data.push(that.series);
            }

            makeSeries("Terminadas", "terminadas", am5.color(0x6794dc));
            makeSeries("En ejecución", "ejecucion", am5.color(0x6771dc));
            /** Unifico las terminadas y en ejecución con terminadasyejecucion */
            //makeSeries("Terminadas y en ejecución", "terminadasyejecucion", am5.color(0x6794dc));
            makeSeries("No iniciadas", "noiniciadas", am5.color(0xff0000));



            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);


        },

        updateChartDOM() {
            /** Refresh css of element chartareascumplimientoaccion change width 90%*/
            let chartdiv = document.getElementById('chartdivbarras');
            chartdiv.style.width = '99.9%';

            setTimeout(() => {
                let chartdiv2 = document.getElementById('chartdivbarras');
                chartdiv2.style.width = '100%';
            }, 100);
        },
        myEventHandler() {
            //console.log("event", e);
            this.updateChartDOM();
        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica {
    width: calc(100% - 520px);
}

.contenedor-grafica #chartdivbarras {
    width: 100%;
}


.contenedor-grafica>div {
    height: 600px;
}

@media screen and (max-width:1200px) {
    .contenedor-grafica {
        width: 100%;
    }
}
</style>