<template>
    <div class="wrapper -indice-cumplimiento">

        <section class="encabezado" data="plan-estrategico">

            <h2 class="title -white">Índice de cumplimiento</h2>

        </section>

        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">Compromisos de Gobierno</router-link>
                <router-link :to="$route.fullPath">Índice de cumplimiento</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <section class="cuadro-resumen-banner -section">
            <div class="container">
                <nav class="row-center">
                    <router-link :to="$t('enlaces.compromisos')">¿Cómo queremos que sea Madrid?</router-link>
                    <router-link :to="$t('enlaces.planoperativo')">¿Cómo vamos a conseguirlo?</router-link>
                    <router-link :to="$t('enlaces.indicecumplimiento')">Índice de cumplimiento</router-link>
                </nav>

                <article class="cuadro row-between">


                    <template>
                        <div class="texto">
                            <h2 class="title -left">Índice de cumplimiento
                            </h2>
                            <p>Informamos de la situación en que se encuentran las actuaciones que forman parte de nuestros
                                compromisos de gobierno desde diferentes visiones: funcional, estratégica, territorial y
                                orgánica.
                            </p>
                        </div>


                        <div class="imagen row-end">

                            <img src="/img/imagen-indice-cumplimiento.jpg" title="Imagen del Ayuntamiento"
                                alt="Imagen del Ayuntamiento">
                        </div>

                    </template>

                </article>

            </div>
        </section>


        <section class="title-container -section">
            <div class="container">
                <h2 class="title -left -primary">Índice de cumplimiento</h2>
            </div>
        </section>

        <section class="container filtro-indice">


            <div class="filtro">
                <div class="inputs row-start">
                    <div class="contenedor-input" v-if="dominios.ejes">
                        <multiselect v-model="filter.ejes" :options="dominios.ejes" placeholder="Eje estratégico"
                            label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                            :showLabels="false" :showNoResults="false" track-by="nombre"></multiselect>
                    </div>

                    <div class="contenedor-input" v-if="dominios.areas_accion">
                        <multiselect v-model="filter.areas_accion" :options="dominios.areas_accion"
                            placeholder="Áreas de Acción" label="nombre" :multiple="true" :closeOnSelect="false"
                            :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                        </multiselect>
                    </div>

                    <div class="contenedor-input" v-if="dominios.areas_gobierno">
                        <multiselect v-model="filter.areas_gobierno" :options="dominios.areas_gobierno"
                            placeholder="Áreas de Gobierno, Distritos..." label="nombre" :multiple="true"
                            :closeOnSelect="false" :tagPosition="'bottom'" :showLabels="false" :showNoResults="false"
                            track-by="nombre">
                        </multiselect>
                    </div>

                    <div class="contenedor-input -m0" v-if="dominios.distritos">

                        <multiselect v-model="filter.distritos" :options="dominios.distritos"
                            placeholder="Ámbito territorial" label="nombre" :multiple="true" :closeOnSelect="false"
                            :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                        </multiselect>

                    </div>

                    <a @click="reset" class="erase">Borrar filtros</a>

                </div>
            </div>

        </section>


        <section class="intro-indice">

            <div class="contenedor-datos-graficas container row-center">
                <!-- /*PENDIENTE*/ -->
                <grafica-percent v-if="stats" :total="stats.total" :label="'Actuaciones'" :terminadas="stats.terminadas"
                    :ejecucion="stats.ejecucion" :planificadas="stats.noiniciadas">
                </grafica-percent>

                <div class="stats row-between">
                    <article class="stat -terminadas">
                        <strong>
                            <count-to :endVal="stats.terminadas" :separator="'.'"></count-to>
                        </strong>
                        <span>Terminadas</span>
                    </article>

                    <article class="stat -ejecucion">
                        <strong>
                            <count-to :endVal="stats.ejecucion" :separator="'.'"></count-to>
                        </strong>
                        <span>En ejecución</span>
                    </article>

                    <article class="stat -no-iniciadas" style="background-color: #ff0000; border-color:#ff0000;">
                        <strong>
                            <count-to :endVal="stats.noiniciadas" :separator="'.'"></count-to>
                        </strong>
                        <span>No iniciadas</span>
                    </article>

                </div>

                <!-- 
                <progress-bar v-if="homedata" :title="'Actuaciones terminadas'"
                    :percent="stats.length*100/actuaciones.length" :color="'green'">
                </progress-bar>


                <count-down v-if="homedata" :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante"
                    :color="'yellow'">
                </count-down> -->

            </div>

        </section>


        <section class="graficas-indice -section" v-if="actuaciones && actuaciones.length != 0">
            <div class="container row-between">
                <div class="graph-container quinto">
                    <!-- <h2 class="title -left -primary">Actuaciones terminadas por Compromiso de Gobierno</h2> -->
                    <h2 class="title -left -primary" style="margin-bottom: 50px;">Actuaciones terminadas y en ejecución</h2>
                    <actuaciones-por-gobierno :datos="actuacionesporgobierno"></actuaciones-por-gobierno>
                </div>

                <div class="graph-container quinto">
                    <h2 class="title -left -primary">Cumplimiento según situación de las actuaciones</h2>
                    <cumplimiento-por-acuerdos></cumplimiento-por-acuerdos>
                </div>

                <div class="graph-container -section">
                    <h2 class="title -left -primary">Cumplimiento por Áreas de Acción</h2>
                    <cumplimiento-por-areas v-if="dominios"></cumplimiento-por-areas>
                </div>

                <div class="graph-container -section" v-if="dominios">
                    <h2 class="title -left -primary">Cumplimiento por ámbito territorial</h2>
                    <cumplimiento-por-distritos></cumplimiento-por-distritos>
                </div>

                <div class="graph-container -section -gobierno-distritos" v-if="dominios">
                    <h2 class="title -left -primary">Cumplimiento por Áreas de Gobierno, Distritos y Pleno</h2>
                    <cumplimiento-por-accion></cumplimiento-por-accion>
                </div>

            </div>
        </section>


        <banner-ayudamos :data="['que-es-pog', 'plan-estrategico', 'observatorio-ciudad']"></banner-ayudamos>

    </div>
</template>


<script>

import router from '@/router';

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

import actuacionesporgobierno from '@/components/parts/actuaciones-por-gobierno-graph'
import cumplimientoporareas from '@/components/parts/cumplimiento-por-areas-graph'
import cumplimientopordistritos from '@/components/parts/cumplimiento-por-distritos'
import cumplimientoporaccion from '@/components/parts/cumplimiento-por-accion-graph'
import cumplimientoporacuerdos from '@/components/parts/cumplimiento-por-acuerdos-graph'

export default {
    name: 'indice-cumplimiento',
    mounted() {
        //this.requestHomeData();
        //this.requestDominios();
    },
    data: () => ({
        filter: {
            ejes: [],
            areas_accion: [],
            areas_gobierno: [],
            distritos: []
        },
    }),
    computed: {
        ...mapGetters({
            homedata: 'getHomedata',
            actuaciones: 'getActuaciones',
            dominios: 'getDominios'
        }),
        stats() {

            let stats = {
                total: 0,
                terminadas: 0,
                noiniciadas: 0,
                ejecucion: 0
            }

            if (this.actuaciones && this.dominios && this.dominios.distritos) {

                let filtered = JSON.parse(JSON.stringify(this.actuaciones));

                //console.log("filtered inicio", filtered);

                //EJES
                if (this.filter.ejes.length != 0) {
                    let ejes = this.filter.ejes.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (ejes.some(eje => eje == actuacion.ideje));
                    })
                }

                //ÁREAS ACCION
                if (this.filter.areas_accion.length != 0) {
                    let areas_accion = this.filter.areas_accion.map(e => e.id);
                    filtered = filtered.filter(actuacion => {

                        let areas_accion_actuacion = actuacion.idareaaccion != null ? actuacion.idareaaccion.split(",").map(e => e.trim()) : [];
                        return (areas_accion.some(area => areas_accion_actuacion.includes(area.toString())));

                        //return (areas_accion.some(area => (area == actuacion.idareaaccion || area == actuacion.idareadeaccion)));
                    })
                }

                //ÁREAS GOBIERNO
                if (this.filter.areas_gobierno.length != 0) {
                    let areas_gobierno = this.filter.areas_gobierno.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (areas_gobierno.some(area => area == actuacion.idareagobierno));
                    })
                }

                //DISTRITOS
                if (this.filter.distritos.length != 0) {
                    let distritos = this.filter.distritos.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (distritos.some(distrito => distrito == actuacion.iddistrito));
                    })
                }

                stats.total = filtered.length;
                stats.terminadas = filtered.filter(e => e.idactuacioncumplimiento == 1).length;
                stats.ejecucion = filtered.filter(e => e.idactuacioncumplimiento == 2).length;
                stats.noiniciadas = filtered.filter(e => e.idactuacioncumplimiento >= 3).length;

            }

            return stats;
        },
        actuacionesporgobierno() {
            let actuaciones = {
                villa: [],
                gobierno: [],
                compromiso: []
            }

            if (this.actuaciones) {
                //actuaciones.villa = this.actuaciones.filter(e => (e.compromisodegobierno) ? e.compromisodegobierno.includes('Acuerdos de la Villa:') : '');
                //actuaciones.gobierno = this.actuaciones.filter(e => (e.compromisodegobierno) ? e.compromisodegobierno.includes('Acuerdo de Gobierno:') : '');

                /** Si es compromiso va sin filtro */
                actuaciones.compromiso = this.actuaciones;
            }

            return actuaciones;
        }

    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        back() {
            router.go(-1);
        },
        reset() {
            this.filter = {
                ejes: [],
                areas_accion: [],
                areas_gobierno: [],
                distritos: [],
            };
        }
    },
    components: {
        'actuaciones-por-gobierno': actuacionesporgobierno,
        'cumplimiento-por-areas': cumplimientoporareas,
        'cumplimiento-por-distritos': cumplimientopordistritos,
        'cumplimiento-por-accion': cumplimientoporaccion,
        'cumplimiento-por-acuerdos': cumplimientoporacuerdos
    }
}
</script>