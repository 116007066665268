<template>
    <div class="contenedor-grafica-areas-accion row-center">


        <!-- <div class="filtro">
            <div class="inputs row-start">
                <div class="contenedor-input" v-if="dominios.ejes">
                    <multiselect v-model="filter.ejes" :options="dominios.ejes" placeholder="Eje estratégico"
                        label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                        :showLabels="false" :showNoResults="false" track-by="nombre"></multiselect>
                </div>


                <div class="contenedor-input" v-if="dominios.areas_accion">
                    <multiselect v-model="filter.areas_accion" :options="dominios.areas_accion"
                        placeholder="Áreas de Acción" label="nombre" :multiple="true" :closeOnSelect="false"
                        :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                    </multiselect>
                </div>


                <div class="contenedor-input -m0" v-if="dominios.distritos">
                    <multiselect v-model="filter.distritos" :options="dominios.distritos" placeholder="Ámbito territorial"
                        label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                        :showLabels="false" :showNoResults="false" track-by="nombre">
                    </multiselect>

                </div>

                <a @click="reset" class="erase">Borrar filtros</a>

            </div>
        </div> -->

        <div class="contenedor-grafica">
            <div id="chartacuerdo" :hidden="actuacionesfiltered.length == 0"></div>

        </div>
    </div>
</template>


<script>

import { mapGetters } from 'vuex';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'cumplimiento-por-areas',
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        if (this.actuaciones.length != 0) {
            this.setChart();
        }
    },
    data: () => ({
        selected: '',
        series: "",
        root: '',
        filter: {
            ejes: [],
            distritos: [],
            areas_accion: []
        },
    }),
    methods: {
        setChart() {

            this.root = am5.Root.new("chartacuerdo");

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                // panX: true,
                // panY: true,
                paddingTop: 20,
                draggable: false,
                wheelable: false,
                zoomControl: false,
                zoomLevel: false,
                zoomStep: false,
                exportable: true,
                layout: this.root.verticalLayout
            }));


            var xRenderer = am5xy.AxisRendererX.new(this.root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                centerY: am5.p50,
                centerX: am5.p50,
                paddingRight: 0
            });

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "name",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            xAxis.data.setAll(this.actuacionesfiltered);

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
                min: 0,
                max: 100,
                numberFormat: "#'%'",
                strictMinMax: true,
                calculateTotals: true,
                renderer: am5xy.AxisRendererY.new(this.root, {})
            }));


            // Add legend
            // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
            let legend = chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50,
                y: am5.p60,
                clickTarget: "none"

            }));

            let that = this;

            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            function makeSeries(name, fieldName, color) {
                that.series = chart.series.push(am5xy.ColumnSeries.new(that.root, {
                    name: name,
                    etiqueta: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    valueYShow: "valueYTotalPercent",
                    categoryXField: "name",
                    fill: color,
                }));

                that.series.columns.template.setAll({
                    tooltipText: "{etiqueta}: {valueYTotalPercent.formatNumber('#.')}%",
                    tooltipY: am5.percent(10)
                });

                // Add custom colors 
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Manual_colors
                // chart.get("colors").set("colors", [
                //     am5.color(0x67b7dc),
                //     am5.color(0x6794dc),
                //     am5.color(0xff0000), // red for no-iniciadas
                //     am5.color(0x86a873),
                //     am5.color(0xbb9f06)
                // ]);

                that.series.data.setAll(that.actuacionesfiltered);

                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                that.series.appear();

                that.series.bullets.push(function () {
                    return am5.Bullet.new(that.root, {
                        sprite: am5.Label.new(that.root, {
                            text: "{valueYTotalPercent.formatNumber('#.')}%",
                            fill: that.root.interfaceColors.get("alternativeText"),
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true
                        })
                    });
                });

                legend.data.push(that.series);
            }

            makeSeries("Terminadas", "terminadas", am5.color(0x6794dc));
            makeSeries("En ejecución", "ejecucion", am5.color(0x6771dc));
            /** Unifico terminadas y en ejecución y las llamo con terminadasyejecucion */
            //makeSeries("Terminadas y en ejecución", "terminadasyejecucion", am5.color(0x6794dc));
            makeSeries("No iniciadas", "noiniciadas", am5.color(0xff0000));



            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);



        },

        setSelected(id) {
            this.selected = id;
            let data = this.setData();
            data.forEach((e, i) => {
                this.series.data.setIndex(i, e);
            })
        },
        reset() {
            this.filter = {
                ejes: [],
                distritos: [],
                areas_accion: [],
            };
        },

        updateChartDOM() {
            /** Refresh css of element chartareascumplimientoaccion change width 90%*/
            let chartdiv = document.getElementById('chartacuerdo');
            chartdiv.style.width = '99.9%';

            setTimeout(() => {
                let chartdiv2 = document.getElementById('chartacuerdo');
                chartdiv2.style.width = '100%';
            }, 100);
        },
        myEventHandler() {
            //console.log("event", e);
            this.updateChartDOM();
        }

    },
    computed: {
        ...mapGetters({
            actuaciones: 'getActuaciones',
            dominios: 'getDominios',
        }),
        actuacionesfiltered() {
            let filtered;
            let areas = new Object();

            if (this.actuaciones && this.dominios) {

                filtered = JSON.parse(JSON.stringify(this.actuaciones));

                //EJES
                if (this.filter.ejes.length != 0) {
                    let ejes = this.filter.ejes.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (ejes.some(eje => eje == actuacion.ideje));
                    })

                }
                //DISTRITOS
                if (this.filter.distritos.length != 0) {
                    let distritos = this.filter.distritos.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (distritos.some(distrito => distrito == actuacion.iddistrito));
                    })
                }


                //ÁREAS ACCION
                if (this.filter.areas_accion.length != 0) {
                    let areas_accion = this.filter.areas_accion.map(e => e.id);
                    filtered = filtered.filter(actuacion => {
                        return (areas_accion.some(area => (area == actuacion.idareaaccion || area == actuacion.idareadeaccion)));
                    })
                }


                let areas = {
                    // gobierno: {
                    //     name: 'Acuerdo de Gobierno',
                    //     actuaciones: 0,
                    //     terminadas: 0,
                    //     noiniciadas: 0,
                    //     ejecucion: 0,
                    //     elementos: ''
                    // },
                    // villa: {
                    //     name: 'Acuerdos de la Villa',
                    //     actuaciones: 0,
                    //     terminadas: 0,
                    //     noiniciadas: 0,
                    //     ejecucion: 0,
                    //     elementos: ''
                    // },
                    compromiso: {
                        name: 'Actuaciones',
                        actuaciones: 0,
                        terminadas: 0,
                        noiniciadas: 0,
                        ejecucion: 0,
                        elementos: ''
                    },
                }


                //areas.villa.elementos = filtered.filter(e => (e.compromisodegobierno) ? e.compromisodegobierno.includes('Acuerdos de la Villa:') : '');
                //areas.gobierno.elementos = filtered.filter(e => (e.compromisodegobierno) ? e.compromisodegobierno.includes('Acuerdo de Gobierno:') : '');

                //console.log("filtered", filtered);
                /** areas.gobiernos.compromisos = sin filtrar */
                areas.compromiso.elementos = filtered;


                for (let i in areas) {
                    areas[i].actuaciones = areas[i].elementos.length;
                    areas[i].terminadas = areas[i].elementos.filter(f => (f.idactuacioncumplimiento == 1)).length;
                    areas[i].ejecucion = areas[i].elementos.filter(f => (f.idactuacioncumplimiento == 2)).length;
                    areas[i].noiniciadas = areas[i].elementos.filter(f => (f.idactuacioncumplimiento >= 3)).length;
                    /** Unifico terminadas y ejecución en terminadasyejecucion */
                    areas[i].terminadasyejecucion = areas[i].terminadas + areas[i].ejecucion;
                }

                return Object.values(areas);


            } else {
                return Object.values(areas);
            }


        }
    },
    watch: {
        'actuaciones'() {
            this.setChart();
        },
        'actuacionesfiltered': {
            deep: true,
            handler() {
                this.root.dispose();
                this.setChart();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-grafica {
    width: 100%;
}

.contenedor-grafica>div {
    width: 100%;
    min-height: 500px;
}

#chartareascumplimiento {
    width: 100%;


    &[hidden=hidden] {
        opacity: 0;
        pointer-events: none;
        filter: grayscale(1)
    }
}
</style>