<template>
    <div class="contenedor-grafica contenedor-gobierno">
        <div id="chartactuacionesporgobierno"></div>
    </div>
</template>

<script>


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";



export default {
    name: 'actuaciones-por-gobierno',
    props: ['datos'],
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.setChart();
    },
    data: () => ({
        root: '',
        series: ''
    }),
    methods: {
        setChart() {

            var root = am5.Root.new("chartactuacionesporgobierno");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);



            var chart = root.container.children.push(am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                draggable: false,
                wheelable: false,
                zoomControl: false,
                zoomLevel: false,
                zoomStep: false,
                innerRadius: am5.percent(50),
                startAngle: -90,
                endAngle: 180
            }));


            // Data
            var data = [
                // {
                //     category: "Acuerdo de Gobierno",
                //     //value: this.datos.gobierno.filter(e => e.idactuacioncumplimiento == 1).length*100/this.datos.gobierno.length,
                //     value: this.datos.gobierno.filter(e => e.idactuacioncumplimiento == 1 || e.idactuacioncumplimiento == 2).length * 100 / this.datos.gobierno.length,
                //     full: 100,
                //     columnSettings: {
                //         fill: chart.get("colors").getIndex(0),
                //         fontSize: 13
                //     }
                // }, 
                // {
                //     category: "Acuerdos de la Villa",
                //     //value: this.datos.villa.filter(e => e.idactuacioncumplimiento == 1).length*100/this.datos.villa.length,
                //     value: this.datos.villa.filter(e => e.idactuacioncumplimiento == 1 || e.idactuacioncumplimiento == 2).length * 100 / this.datos.villa.length,
                //     full: 100,
                //     columnSettings: {
                //         fill: chart.get("colors").getIndex(1),
                //         fontSize : 13
                //     },
                // }, 
                /** Categoria Compromiso de Gobierno */
                {
                    category: "",
                    //value: this.datos.gobierno.filter(e => e.idactuacioncumplimiento == 1).length*100/this.datos.gobierno.length,
                    value: Math.round(this.datos.compromiso.filter(e => e.idactuacioncumplimiento == 1 || e.idactuacioncumplimiento == 2).length * 100 / this.datos.compromiso.length),
                    full: 100,
                    columnSettings: {
                        fill: chart.get("colors").getIndex(1),
                        fontSize: 13
                    },
                }
            ];

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
            var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
                //behavior: "zoomX"
            }));

            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Create axes and their renderers
            // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
            var xRenderer = am5radar.AxisRendererCircular.new(root, {
                //minGridDistance: 50
            });

            xRenderer.labels.template.setAll({
                radius: 10
            });

            xRenderer.grid.template.setAll({
                forceHidden: true
            });

            var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: 100,
                strictMinMax: true,
                numberFormat: "#'%'",
                //tooltip: am5.Tooltip.new(root, {})
            }));


            var yRenderer = am5radar.AxisRendererRadial.new(root, {
                minGridDistance: 20
            });

            yRenderer.labels.template.setAll({
                centerX: am5.p100,
                fontWeight: "500",
                fontSize: 18,
                templateField: "columnSettings"
            });

            yRenderer.grid.template.setAll({
                forceHidden: true
            });

            var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: yRenderer
            }));

            yAxis.data.setAll(data);


            // Create series
            // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
            var series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "full",
                categoryYField: "category",
                fill: root.interfaceColors.get("alternativeBackground"),
            }));

            series1.columns.template.setAll({
                width: am5.p100,
                fillOpacity: 0.08,
                strokeOpacity: 0,
                cornerRadius: 20,
            });

            series1.data.setAll(data);

            /** la serie2 es la de la actuación  */
            var series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                clustered: false,
                valueXField: "value",
                categoryYField: "category"
            }));

            series2.columns.template.setAll({
                width: am5.p100,
                strokeOpacity: 0,
                //tooltipText: "{valueX.formatNumber('#.##')}%",
                cornerRadius: 20,
                templateField: "columnSettings"
            });

            /** Agregando etiquetas a la serie2 con el porcentaje */
            series2.bullets.push(function (root) {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    //locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        text: "{valueX.formatNumber('#.##')}%",
                        fontSize: 16,
                        fontWeight: "600",
                        centerX: am5.percent(35),
                        centerY: am5.percent(0),
                        populateText: true,
                        align: "center",
                        //paddingBottom: 30,
                    })
                });
            });

            series2.data.setAll(data);

            // Animate chart and series in
            // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
            series1.appear(1000);
            series2.appear(1000);
            chart.appear(1000, 100);

            /** Testeo de eventos */
            // series2.events.on("datavalidated", function(ev) {
            //     console.log("series1.datavalidated", ev);
            //     });

            // xAxis.onPrivate("selectionMin", function(start, target) {
            //     console.log("Start value changed:", start, target);

            //     /** delete behavior: "zoomX" at chart */
            //     // chart.set("cursor", am5radar.RadarCursor.new(root, {
            //     //     //behavior: ""
            //     // }));
            // });

            // xAxis.onPrivate("selectionMax", function(end, target) {
            //     console.log("End value changed:", end, target);
            //     });

        },

        updateChartDOM() {
            /** Refresh css of element chartareascumplimientoaccion change width 90%*/
            let chartdiv = document.getElementById('chartactuacionesporgobierno');
            chartdiv.style.width = '99.9%';

            setTimeout(() => {
                let chartdiv2 = document.getElementById('chartactuacionesporgobierno');
                chartdiv2.style.width = '100%';
            }, 100);
        },
        myEventHandler() {
            //console.log("event", e);
            this.updateChartDOM();
        }
    }
}
</script>

<style lang="scss" scoped>
#chartactuacionesporgobierno {
    width: 100%;
    height: 100%;
    margin-top: -50px;
}

.contenedor-gobierno {
    /** establezco un height mayor */
    height: 80%;
}

/* El primer div hijo de .contenedor-grafico hereda el height */
.contenedor-grafico>div {
    height: 100%;
}
</style>